import React, { useState } from 'react';
import './style.scss';
import Partner1 from "../../../images/landing/partnters/1.png";
import Partner2 from "../../../images/landing/partnters/2.png";
import Partner3 from "../../../images/landing/partnters/3.png";
import Partner4 from "../../../images/landing/partnters/4.png";
import Partner5 from "../../../images/landing/partnters/5.png";
import Partner6 from "../../../images/landing/partnters/6.png";
import Partner7 from "../../../images/landing/partnters/7.png";
import Partner9 from "../../../images/landing/partnters/9.png";
import Partner10 from "../../../images/landing/partnters/10.png";
import Partner11 from "../../../images/landing/partnters/11.png";
import Partner12 from "../../../images/landing/partnters/12.svg";
import Partner13 from "../../../images/landing/partnters/13.png";
import Partner14 from "../../../images/landing/partnters/14.svg";
import Partner15 from "../../../images/landing/partnters/15.png";
import { useEffect } from "react";
import { useMediaQuery } from '@material-ui/core';



const StrategicPartners:React.FC=()=> {
  const mobile = useMediaQuery('(max-width: 768px)')
  const [aosProps,setAosProps]=useState({
    'data-aos-duration': "1000",
    'data-aos-anchor-placement': 'center-bottom',
    'data-aos': 'fade',
    'data-aos-anchor':"#partners"
  })
  useEffect(()=>setAosProps(
    {
      'data-aos-duration': "1000",
      'data-aos-anchor-placement': 'center-bottom',
      'data-aos': 'fade',
      'data-aos-anchor':mobile?'':"#partners"
    }
  ),[mobile])
  useEffect(() => {
    const myId:string = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView({block: "center"});
    }
  }, [])
  return (
    <div className="strategic-partners" id="partners">
      <div className="container">
        <div className="title">Strategic Partners</div>
        <div className="partners-list">
          <div className="partner" {...aosProps } data-aos-delay = '100' >
            <img src={Partner1} alt=""loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '400'>
            <img src={Partner2} alt="" className="archax" loading="lazy" />
          </div>
          <div className="partner" {...aosProps }>
            <img src={Partner3} alt="" loading="lazy" data-aos-delay = '300'/>
          </div>
          <div className="partner">
            <img src={Partner4} alt="" loading="lazy"{...aosProps } data-aos-delay = '200'/>
          </div>
          <div className="partner" style={{marginTop:'-17px'}}{...aosProps } data-aos-delay = '100'>
            <img src={Partner10} alt="" loading="lazy"/>
          </div>
          <div className="partner"{...aosProps } data-aos-delay = '400'>
            <img src={Partner5} alt="" loading="lazy"/>
          </div>
          <div className="partner"{...aosProps } data-aos-delay = '200'>
            <img src={Partner6} alt="" loading="lazy"/>
          </div>
          <div className="partner"{...aosProps } data-aos-delay = '300'>
            <img src={Partner7} alt="" loading="lazy" />
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '100'>
            <img src={Partner12} width={85} alt="" loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '200'>
            <img src={Partner9} alt="" loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '300'>
            <img src={Partner11} alt="" width={150} loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '300'>
            <img src={Partner15} alt="" width={100} loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '400'>
            <img src={Partner14} alt="" width={130} loading="lazy"/>
          </div>
          <div className="partner" {...aosProps } data-aos-delay = '500'>
            <img src={Partner13} alt="" width={150} loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrategicPartners;
