import React from "react";
import Opportunity from "../../images/landing/opportunity.png";
import Tokenization from "../../images/landing/tokenization.png";
import Trade from "../../images/landing/trade.png";
import Arrows from "../../images/landing//arrows.png";
import ArrowsDown from '../../images/landing/arrows-down.png';
import { useMediaQuery } from "@material-ui/core";


const baseUrl = process.env.GATSBY_BASE_URL;

const aosProps = {
  'data-aos-duration': "500",
  'data-aos-anchor-placement': 'center-bottom',
  'data-aos': "fade-up",
}

const Explained: React.FC = () => {
  const mobile = useMediaQuery('(max-width: 768px)')
  return (
    <>
      <div className="hidden-height" id="explained"></div>
      <section className="explained">
        <div className="container">
          <span className="title" data-aos="fade-up" data-aos-duration="500">
            DIBS Capital
          </span>
          <span className="descr" data-aos="fade-up" data-aos-duration="500">
            Distributed Investment Banking
          </span>
          <div className="process">
            <div
              className="process__item"
              {...aosProps}
            >
              <div className="process__img">
                <img height={155} src={Opportunity} alt="real estate coins" loading="lazy" />
                <img src={Arrows} alt="" className="arrows" loading="lazy" />
              </div>
              <img src={ArrowsDown} alt="" className="arrows-down arrows-down1" loading="lazy" />
              <span className="title">Web3 Digitization</span>
              <span className="descr">
              First stage to enable liquidity for a private asset or company is to securitize a block of shares (usually under 50%) and to
              issue ‘tokens’ - Web3 digital assets - to make it easier to digitally distribute and trade those shares.
              </span>
            </div>
            <div
              className="process__item"
              {...aosProps}
              data-aos-delay={!mobile ? "300" : '0'}
            >
              <div className="process__img tokenization">
                <img height={155} src={Tokenization} alt="tokenization real estate" loading="lazy" />
                <img src={Arrows} alt="" className="arrows" loading="lazy" />
              </div>
              <img src={ArrowsDown} alt="" className="arrows-down arrows-down2" loading="lazy" />
              <span className="title">Trading Assets</span>
              <span className="descr">
              Digital tokens representing shares in your business are distributed and traded via Web3-enabled digital platforms.
              DIBS Capital ensures full compliance with US and global regulation.
              </span>
            </div>
            <div
              className="process__item"
              {...aosProps}
              data-aos-delay={!mobile ? "600" : '0'}
            >
              <div className="process__img">
                <img
                  height={155}
                  src={Trade}
                  alt="tokenized real estate investment"
                  loading="lazy" />
              </div>
              <span className="title">Distributed brokerage</span>
              <span className="descr">
              DIBS is unique - we connect independent licensed brokers to our web3 platform to let them distribute any asset
              listed on DIBS while paying them commission every time they connect investors to listed deals.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Explained;
