import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import Control from '../../images/landing/control.png';
import Global from '../../images/landing/global.png';
import Star from '../../images/landing/star.png';

const aosProps = {
    'data-aos-duration': "1000",
    'data-aos-anchor-placement': 'bottom-bottom',
}

const WhySolidblock: React.FC = () => {
    const mobile = useMediaQuery('(max-width: 768px)')
    return (
        <section className="why-solidblock">
            <div className="container">
                <div className="title" data-aos="fade-down" {...aosProps} >
                    Why DIBS
                </div>
                <div className="subtitle" data-aos="fade-down" {...aosProps} >
                    Access to Capital Distribution
                </div>
                <div className="benefits">
                    <div
                        className="benefits-item"
                        data-aos={!mobile ? "fade-up-right" : "fade-up"}
                        {...aosProps}
                    >
                        <img src={Control} alt="" loading="lazy" />
                        <div className="title">
                            First to Tokenize
                        </div>
                        <div className="descr">
                            DIBS’ predecessor, SolidBlock - a property tokenization company - created the industry standard with the tokenization of the St.
                            Regis Hotel in Aspen, CO. SolidBlock went on to form a new venture, DIBS Capital, with a licensed broker dealer, Viant Capital.
                        </div>
                    </div>
                    <div className="benefits-item" data-aos="fade-up" {...aosProps} >
                        <img src={Global} alt="" loading="lazy" />
                        <div className="title">
                            First Distributed Broker
                        </div>
                        <div className="descr">
                            DIBS Capital is fully regulated and compliant in accordance with FINRA & SEC regulations. In addition to issuing web3-enabled
                            securities (tokens), DIBS connects between affiliated brokers on par with established broker commission sharing rules.
                        </div>
                    </div>
                    <div
                        className="benefits-item"
                        data-aos={!mobile ? "fade-up-left" : "fade-up"}
                        {...aosProps}>
                        <img src={Star} alt="" loading="lazy" />
                        <div className="title">
                            First Fund of Tokens
                        </div>
                        <div className="descr">
                            Our expertise in securities and structured finance, as well as rules of capital markets, trading, compliance and liquidity
                            creation position us the first compliant platform for creation of index funds from security tokens.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhySolidblock;