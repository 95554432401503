import Collapse from "@material-ui/core/Collapse";
import LogOut from "../images/log-out.svg";
import Person from "../images/person.svg";
import React from "react"


const AccountNavCollapse = ({ open, setOpen, admin, renderBanner }) => {
    const baseUrl = process.env.GATSBY_BASE_URL;

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("expiredTime");
        localStorage.removeItem("refreshToken");
        window.location.assign(`${baseUrl}/`);
    };


    return (
        <div onMouseLeave={() => setOpen(!open)} className="nav-collapse-container" >
            <Collapse in={!open} className="nav-collapse">
                <div
                    className="collapse-header-item"
                >
                    <div className="collapse-header-text" onClick={() => {window.location.assign(`${baseUrl}/profile/main-information`)}}>
                        <img
                            alt="person"
                            src={Person}
                            className="collapse-person"
                        />
                        Profile
                    </div>
                    {renderBanner}
                </div>
                {admin}
                <div className="collapse-header-item">
                    <div className="collapse-header-text" onClick={() => logout()}>
                        <img
                            alt="logout"
                            src={LogOut}
                            className="collapse-logout"
                        />
                        Log out
                    </div>
                </div>
            </Collapse>
        </div >
    );
};

export default AccountNavCollapse;