import axios from "axios";

const isBrowser = typeof window !== "undefined"
const url = process.env.GATSBY_API_KEY;

const refreshTokenApi = () => axios.post(process.env.GATSBY_API_KEY + 'auth/refreshToken', {
    accessToken: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken')
}
).then((res: any) => {
    localStorage.setItem('token', res.data.token)
    localStorage.setItem('refreshToken', res.data.refreshToken)
    localStorage.setItem('expiredTime', res.data.expiredTime)
})
    .catch((err: any) => {
        if (err.response.status === 400) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expiredTime");
        }
    })


const getApi = async (endpoint: any) => {
  let ourDate: any
  let token:string|null =''
  if (isBrowser) {
    ourDate = localStorage.getItem("expiredTime");
    token = localStorage.getItem("token");
  }
  let final: any = new Date(ourDate * 1000);
  let date: any = new Date();
  if (final - date <= 0 && ourDate) {
    await refreshTokenApi();
  }
  return axios
    .get(url + endpoint, {
      headers: {
        Authorization: token,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    })
    .then((res: any) => res)
    .catch((err: any) => {
      if (err?.response?.status === 401) {
        if (isBrowser) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiredTime");
        }
      }
      return err;
    });
};

export { getApi };
