import React, { useState, useEffect } from "react";
import LogoCube from "../images/icon.png";
import LogoDark from "../images/DIBS-Letters-new.svg";
import MenuIcon from "../images/menu.svg";
import Close from "../images/close.svg";
import Dropdown from "../images/dropdown.svg";
import DefaultImage from "../images/default-avatar.svg";
import Menu from "./dropDown";
import MenuSpace from "./dropDownSpace";
import MenuTokenization from "./dropTokenizationDown";
import AccountNavCollapse from "./accountNavCollapse";
import LockIcon from "@material-ui/icons/LockOutlined";
import { Button } from "@material-ui/core";
import LogOut from "../images/log-out.svg";

const isBrowser = typeof window !== "undefined"
const baseUrl = process.env.GATSBY_BASE_URL;

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiredTime");
  localStorage.removeItem("refreshToken");
  window.location.assign(`${baseUrl}/`);
};

const refreshTokenApi = () => fetch('https://solidblock.co/auth/refreshToken', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    accessToken: localStorage.getItem("token"),
    refreshToken: localStorage.getItem("refreshToken")
  })
}).then((res) => {
  localStorage.setItem('token', res.data.token)
  localStorage.setItem('refreshToken', res.data.refreshToken)
  localStorage.setItem('expiredTime', res.data.expiredTime)
})
  .catch((err) => {
    if (err.response.status === 400) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiredTime");
    }
  })

export default function LandingNav() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [userImage, setUserImage] = useState('');
  const [isLogined, setIsLogined] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [reminder, setReminder] = useState(false);

  const apiUrl = process.env.GATSBY_API_KEY;
  const baseUrl = process.env.GATSBY_BASE_URL;
  useEffect(() => {
    let ourDate = localStorage.getItem("expiredTime");
    let final = new Date(ourDate * 1000);
    let date = new Date();
    if (final - date <= 0 && ourDate) {
      refreshTokenApi();
    }
    fetch(`${apiUrl}user`, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      }
    })
      .then((res) => {
        setIsUserLoading(false)
        if (res.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expiredTime");
        }
        return res.json()
      }).then(r => {
        setIsLogined(!!r.email)
        setUserImage(r.imagePath);
        setIsAdmin(r.role >= 10)
        setUserData(r.steps);
      })
  }, []);

  const renderBanner = () => {
    if (!userData) return
    //common cases 
    if (!userData.email) {
      return <a href={`${baseUrl}/sign-up/verify-email`} className="menuBanner">verify email</a>
    }
    if (!userData.cell) {
      return <a href={`${baseUrl}/sign-up/phone-country`} className="menuBanner">verify phone</a>
    }
    if (userData.isEntity) {
      if ((!userData.companyInformation)) {
        return <a href={`${baseUrl}/sign-up/company-information`} className="menuBanner">verify accreditation</a>
      }
    }
    if (!userData.isEntity) {
      //individual case
      if (!userData.identity) {
        return <a href={`${baseUrl}/sign-up/kyc`} className="menuBanner">verify id</a>
      }
      if ((!userData.accreditation && !userData.accreditationSkipped)) {
        return <a href={`${baseUrl}/sign-up/accreditation`} className="menuBanner">verify accreditation</a>
      }
    }
  }

  useEffect(() => {
    if (!userData) return
    //remove fields accreditationSkipped ready , coz this fields should not affect on reminder
    let needReminder = Object.entries(userData).filter(a => a[0] !== 'accreditationSkipped' && a[0] !== 'ready').map(i => i[1]).includes(false)
    setReminder(needReminder);
  }, [userData])

  const userImageUrl = userImage || DefaultImage;

  const mobileMenuHandler = () => {
    document.body.classList.toggle("overflow");
    setMenuOpen(!menuOpen);
  };

  const [mobileSubmenuOpen, setMobileSubmenuOpen] = useState(false);
  const [mobileSubmenuSpaceOpen, setMobileSubmenuSpaceOpen] = useState(false);
  const [mobileSubmenuTokenizationOpen, setMobileSubmenuTokenizationOpen] = useState(false);

  let adminLink = ''
  if (isBrowser) {
    adminLink = `${process.env.GATSBY_ADMIN_LINK}/auth?token=${localStorage.getItem("token")}&refreshToken=${localStorage.getItem("refreshToken")}&expiredTime=${localStorage.getItem("expiredTime")}`;
  }
  const adminLinkElement = isBrowser && localStorage.getItem("token") ? (
    <a
      className="collapse-header-item"
      href={adminLink}
      style={{ textDecoration: "none" }}
    >
      <div className="collapse-header-text">
        <LockIcon
          fill="#333333"
          style={{ width: 20, marginRight: 7 }}
        />
        Admin
      </div>
    </a>
  ) : null;

  const renderBannerElement = isBrowser && localStorage.getItem("token") ? (
    <>
      {renderBanner()}
    </>
  ) : null;

  return (
    <header className={`new-landing-nav scrolled`}>
      <div className="container" onMouseLeave={() => setOpen(true)}>
        <a className="logo" href={`${baseUrl}/`} onClick={() => window.scrollTo(0, 0)}>
          <img src={LogoCube} alt="" className="logo-cube" />
          <img src={LogoDark} alt="" className="logo-main" />
        </a>
        <nav className="nav">
          <div className="navSubmenus">
            <a href={`${baseUrl}/about`} className="menu-item">
              About
            </a>
            <a href={`${baseUrl}/brokers`} className="menu-item">
              Brokers
            </a>
            <span className="menu-item dropdown">
              <MenuTokenization />
            </span>
            <span className="menu-item dropdown">
              <Menu />
            </span>

          </div>
          <div>
            <div className="landing-header-desktop-buttons">
              {
                isLogined ? (
                  <>
                    <Button
                      style={{ backgroundColor: "#8A00F6" }}
                      className="get-started-button"
                      onClick={() => window.location.assign(`${baseUrl}/tokenization-form`)}
                    >
                      Tokenize
                    </Button>
                    <div className={`logout ${reminder ? 'reminder' : ''}`}>
                      <div className="logout-icon-container">
                        <img
                          onMouseOverCapture={() => setOpen(false)}
                          alt="logout-icon"
                          src={userImageUrl}
                          onClick={() => setOpen(!open)}
                        ></img>
                      </div>
                      <AccountNavCollapse
                        setOpen={setOpen}
                        open={open}
                        admin={isAdmin ? adminLinkElement : null}
                        renderBanner={renderBannerElement}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <a
                      href="https://forms.gle/NwNGDaA6KiNWsdiv8"
                      className="get-started-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Broker Signup
                    </a>
                  </>
                )}
            </div>
          </div>
        </nav>
        <div className="burger-nav" onClick={mobileMenuHandler}>
          <img src={MenuIcon} alt="" />
        </div>
      </div>
      <div className={`mobile-menu ${menuOpen ? "open" : "hidden"}`}>
        <div className="top">
          <div className="logo">
            <img src={LogoCube} alt="" className="logo-cube-mobile" />
            <img src={LogoDark} alt="" className="logo-main-mobile" />
          </div>
          <img src={Close} alt="Close" onClick={mobileMenuHandler} />
        </div>
        <div className="nav-mobile">
          <span
            className="nav-link"
            onClick={() => {
              setMobileSubmenuOpen(!mobileSubmenuOpen);
            }}
          >
            Learn {"  "}
            <img alt="dropdown" src={Dropdown} />
          </span>
          <div
            className={`dropdown-mobile ${mobileSubmenuOpen ? "active" : ""}`}
          >
            <a href={`${baseUrl}/real-estate-tokenization`} className="sub-link">
              Understanding Tokenization
            </a>
            <a
              className="sub-link"
              href="https://ams.solidblock.co/solidblock-value-video"
              target="_blank" rel="noreferrer"
            >
              How to Make Assets Liquid (Video)
            </a>
            <a href={`${baseUrl}/DAH`} className="sub-link">
              Digital Asset Ebook
            </a>
            <a href={`${baseUrl}/course`} className="sub-link">
              Tokenization Course
            </a>
            <a
              className="sub-link"
              href="https://blog.dibscapital.com/"
              target="_blank" rel="noreferrer"
            >
              Blog
            </a>
            <a
              className="sub-link"
              href="https://podcast.dibscapital.com/"
              target="_blank" rel="noreferrer"
            >
              Podcast
            </a>
          </div>
          <span
            className="nav-link"
            onClick={() => {
              setMobileSubmenuTokenizationOpen(!mobileSubmenuTokenizationOpen);
            }}
          >
            Tokenization {"  "}
            <img alt="dropdown" src={Dropdown} />
          </span>
          <div
            className={`dropdown-mobile ${mobileSubmenuTokenizationOpen ? "active" : ""}`}
          >
            <a href={`${baseUrl}/tokenization-form`} className="sub-link">
              Tokenize your Asset
            </a>
          </div>
          <a href={`${baseUrl}/about`} className="nav-link">
            About
          </a>
          <a href={`${baseUrl}/brokers`} className="nav-link">
            Brokers
          </a>
        </div>

        <div className="bottom">
          <div style={{ width: '100%' }}>
            <a
              href="https://forms.gle/NwNGDaA6KiNWsdiv8"
              className="tokenize"
              target="_blank"
              rel="noreferrer"
            >
              Broker Signup
            </a>
          </div>
        </div>
      </div >
    </header >
  );
}