import React from "react";
import subscribe from "../../images/subscribe.png";
import { useState } from "react";
import { validateEmail } from '../../constants/regex';
import mautic from 'mautic-tracking';
import './modal.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

const aosProps = {
  'data-aos-duration': "1000",
  'data-aos-anchor-placement': 'bottom-bottom',
  'data-aos':"fade-up",
}

const Subscribe: React.FC = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const openTooltip = () => {
        setOpen(true);
    }

    const subscribeClick = () => {
        setError("");
        if (!email) {
            setError("Please, enter your email");
            return;
        }
        if (!validateEmail(email)) {
            setError("Invalid email");
            return;
        }
        mautic.pageView({ email: email, tags: 'news_letter_subscription'});
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 2000);
    }

    return (
    <section className="subscribe podcast" >
        <div className="container">
            <img src={subscribe} alt="" {...aosProps} loading="lazy"/>
            <div className="title" {...aosProps}>Signup for the DIBS Newsletter</div>
            <div className="descr" {...aosProps}>
                Stay tuned to hear about our latest developments in the dynamic world of web3 finance and tokenization. 
                Gain exclusive access to special deals tailored for both investors and asset owners.
            </div>
            <div className="input-block">
                <div className="input">
                    <input
                        className="emailDownload"
                        placeholder="Enter Your Email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                    />
                    <Button
                        variant="contained"
                        className="downloadBtn"
                        onClick={subscribeClick}>
                        Subscribe
                    </Button>
                </div>
                <span className="input-error">{error}</span>
            </div>
            <Dialog
                className="thanks-modal"
                open={open}
                onClose={() => setOpen(false)}>
                <span className="title">
                    Thank You for Subscribing
                </span>
            </Dialog>
        </div>
    </section>
  );
}

export default Subscribe;
