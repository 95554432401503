import React from 'react';
import course from '../../images/landing/course.png'

const baseUrl = process.env.GATSBY_BASE_URL;

const aosProps = {
    'data-aos-duration': "1000",
    'data-aos-anchor-placement': 'bottom-bottom',
    'data-aos': "fade-up",
}

const TokenizationCourse: React.FC = () => {
    return (
        <section className="course">
            <div className="container">
                <img src={course} {...aosProps} alt="" loading="lazy" />
                <div className="title" {...aosProps}>Tokenization 101 Course</div>
                <div className="descr" {...aosProps}>
                    DIBS offers an intensive introductory 10-session course on the power of tokenization to
                    leverage change in the blockchain economy. Taught by DIBS CEO Yael Tamar.
                </div>
                <span
                    {...aosProps}
                    className="enter"
                    onClick={() => window.location.assign(baseUrl + "/course")}
                >
                    View Course Syllabus
                </span>
            </div>
        </section>
    )
}

export default TokenizationCourse;