import React from 'react';
import book from '../../../images/landing/book.png'
import mautic from 'mautic-tracking';
import { useState } from 'react';
import DownloadModal from './downloadModal';
import { getApi } from '../../../api/getApi';



const aosProps = {
    'data-aos-duration': "1000",
    'data-aos-anchor-placement': 'bottom-bottom',
    'data-aos': "fade-up",
}

const Awwards = ({ email }: { email: string }) => {
    const [isModalOpen, SetIsModalOpen] = useState(false)

    const downloadBook = async () => {
        if (email) {
            mautic.pageView({ email, tags: 'download_book' });
            const docLink = 'https://sb-all-documents.s3.amazonaws.com/CRPM+Final+Draft.pdf'
            const a = document.createElement("a");
            a.href = docLink;
            a.target = "_blank"
            a.download = 'true'
            a.click();
        } else {
            SetIsModalOpen(true);
            return;
        }
    }
    return (
        <section className="buzz" id="download">
            <div className="container">
                <div className="slide-inner">
                    <div className="slideWithImg" >
                        <img
                            src={book}
                            {...aosProps}
                            alt="digital assets cryptocurrency"
                            loading="lazy"
                        />
                        <div className="slide-descr" {...aosProps}>
                            Download <span>The Digital Asset Handbook</span> and learn about the exciting changes affecting our economic future.
                        </div>
                        <span
                            className="enter"
                            onClick={downloadBook}
                            {...aosProps}
                        >
                            Download Now
                        </span>
                    </div>
                </div>
            </div>
            <DownloadModal
                isOpen={isModalOpen}
                SetIsModalOpen={SetIsModalOpen}
            />
        </section>
    )
}

export default Awwards;