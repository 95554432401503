import React from "react";
import HeroBGDesktop from "../../images/landing/hero-bg-desktopSmall.png";
import HeroBGMobile from "../../images/landing/hero-bg-mobile.png";
import Cryptos from "../../images/landing/cryptos.png";
import Partner1 from "../../images/landing/seenOn/1.png";
import Partner2 from "../../images/landing/seenOn/2.png";
import Partner3 from "../../images/landing/seenOn/3.png";
import Partner4 from "../../images/landing/seenOn/4.png";
import Partner5 from "../../images/landing/seenOn/5.png";
import Partner6 from "../../images/landing/seenOn/6.png";
import Partner7 from "../../images/landing/seenOn/7.png";
import Partner8 from "../../images/landing/seenOn/8.png";
import Partner9 from "../../images/landing/seenOn/9.png";
import Partner10 from "../../images/landing/seenOn/10.png";

const baseUrl = process.env.GATSBY_BASE_URL;

const LandingHero: React.FC = () => {
  return (
    <section className="hero">
      <div className="main">
        <div className="container">
          <div className="hero-content">
            <img
              src={HeroBGDesktop}
              alt="digital marketplace platform"
              width='564.75'
              className="hero-bg-desktop"
              loading="lazy"
            />
            <img
              src={HeroBGMobile}
              alt="digital marketplace platform"
              className="hero-bg-mobile"
              loading="lazy"
            />
            <div className="title">
              Connecting private businesses with capital markets
            </div>
            <div
              className="subtitle"
            >
              Distributed investment banking unlocks liquidity for private assets with broker distribution of digitized shares
            </div>

            <div className="hero-button-container">
              <div className="hero-button-container__block">
                <a href={baseUrl + "/tokenization-form"}>
                  <button className="hero-button-container__block__btn-left">
                    List an Asset
                  </button>
                </a>
              </div>
              <div className="hero-button-container__block">
                <a
                  href="https://forms.gle/NwNGDaA6KiNWsdiv8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="hero-button-container__block__btn-right">
                    Join as a Broker
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="strategic-partners">
        <div className="container">
          <div className="title" style={{ color: "black" }}>As Seen On</div>
          <div className="partners-list">
            <a href="https://www.businesswire.com/news/home/20201230005175/en/SolidBlock-to-List-150-Million-in-Real-Estate-Security-Tokens-on-INX" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner1} alt="" loading="lazy" />
            </a>
            <a href="https://cointelegraph.com/news/ey-invests-100-million-to-expand-blockchain-product-suite-launches-defi-contract-simulator" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner2} alt="" loading="lazy" />
            </a>
            <a href="https://finance.yahoo.com/news/solidblock-lets-real-estate-investors-130000206.html?guccounter=1" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner3} alt="" loading="lazy" />
            </a>
            <a href="http://cryptocapitalnews.com/real-estate-shark-tank-tokenization-edition-the-most-important-real-estate-and-blockchain-event-of-the-year/" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner4} alt="" loading="lazy" />
            </a>
            <a href="https://waow.marketminute.com/article/getnews-2022-4-1-solidblock-gives-real-estate-developers-a-new-way-of-raising-capital-using-blockchain" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner5} alt="" loading="lazy" />
            </a>
            <a href="https://www.themarker.com/realestate/.premium-MAGAZINE-1.8471845" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner6} alt="" loading="lazy" />
            </a>
            <a href="https://markets.financialcontent.com/fourptsmedia.kutv/news/read/42305884" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner7} alt="" loading="lazy" />
            </a>
            <a href="https://fwnbc.marketminute.com/article/getnews-2022-4-1-solidblock-gives-real-estate-developers-a-new-way-of-raising-capital-using-blockchain" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner8} alt="" loading="lazy" />
            </a>
            <a href="https://www.cointelligence.com/content/author/yael-tamar/" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner9} alt="" loading="lazy" style={{ marginBottom: "15px" }} />
            </a>
            <a href="https://www.marketwatch.com/press-release/solidblock-gives-real-estate-developers-a-new-way-of-raising-capital-using-blockchain-2022-04-01" className="partner" target="_blank" rel="noopener noreferrer">
              <img src={Partner10} alt="" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="accepted">
        <div className="content" data-aos="fade" data-aos-duration="1000">
          <div className="cryptos">
            <img src={Cryptos} alt="" width={405}  className="cryptos" loading="lazy"/>
          </div>
          <span className="descr">Accepted here</span>
        </div>
      </div> */}
    </section>
  );
}

export default LandingHero
