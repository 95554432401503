import React from "react";
import CapitalBG from "../../images/landing/capital.png";
import More from "../../images/landing/more.svg";

const baseUrl = process.env.GATSBY_BASE_URL;

const aosProps = {
  'data-aos-duration': "1000",
  'data-aos-anchor-placement': 'center-bottom',
}

function RaiseCapital() {
  return (
    <>
      <div className="hidden-height" id="raise"></div>
      <section className="raise-capital" id='trigger-raise'>
        <div className="container">
          <div
            {...aosProps}
            data-aos="fade-right"
            className="capital-bg"
          >
            <img src={CapitalBG} 
            alt="tokenized real estate platform" 
            loading="lazy" />
          </div>
          <div className="content">
            <div className="title" data-aos="fade-up" {...aosProps}>
              Looking to enable your asset for trading in a web3 environment?
            </div>
            <div className="descr" data-aos="fade-up" {...aosProps}>
              DIBS’ TokenSuite platform makes it easy to issue, manage and allocate tokens. You’ll leverage 
              blockchain for its decentralization, transparency and immutability and discover the benefits 
              of automated Books and Records, KYC/AML whitelists, distribution of funds, and financial due 
              diligence that’s integrated directly into the token. Tokenization creates a paperless way to 
              manage investors compliantly.            
            </div>
            <a
              className="apply"
              href={baseUrl + "/tokenization-form"}
              data-aos="flip-up"
              {...aosProps}
            >
              Apply For Tokenization
            </a>
            <a className="learn-more"
              href={baseUrl + "/real-estate-tokenization"}
              data-aos="flip-up"
              {...aosProps}
            >
              Learn More {"  "}
              <img src={More} />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default RaiseCapital;
