import React, { useState } from "react";
import { useEffect } from "react";
import mautic from 'mautic-tracking';
import "../styles/main.scss"
import LandingNav from "../components/landing-nav"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"
import LandingHero from "../components/landing/hero";
import '../components/landing/style.scss'
import LandingCards from "../components/landing/cards";
import Explained from "../components/landing/explained";
import Aos from "aos";
import 'aos/dist/aos.css'
import Buzz from "../components/landing/buzz";
import RaiseCapital from "../components/landing/raiseCapital";
import Awwards from "../components/landing/awwards";
import WhySolidblock from "../components/landing/whySolidblock";
import Podcast from "../components/landing/podcast";
import TokenizationCourse from "../components/landing/tokenizationCourse";
import StrategicPartners from "../components/landing/strategicPartners";
import Blog from "../components/landing/blog";
import Subscribe from "../components/landing/subscribe";
import homeOG from "../images/homeOG.jpg"
import { getApi } from "../api/getApi";



const TokenizationPage: React.FC = () => {
    const baseUrl = process.env.GATSBY_BASE_URL;
    const [userData, setUserData] = useState<any>(null)

    async function getUserData() {
        const userData = await getApi(`user`)
        return setUserData(userData.data)
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getUserData()
        }
        Aos.init({
            duration: 2000,
            once: true,
        });

        mautic.initialize('https://ams.solidblock.co/mtc.js')
        mautic.pageView({});

        const myId = window.location.hash.slice(1);
        const elem = document.getElementById(myId);

        if (elem) {
            elem.scrollIntoView();
        }
        const resize = new ResizeObserver(() => {
            Aos.refresh()
        })
        resize.observe(document.querySelector('body') as HTMLBodyElement)
    }, [])

    return (
        <div className="new-landing">
            <div className="seo">
                <h1> Distributed investment banking </h1>
                <h2> We Transform Real Estate into Digital Shares </h2>
                <h2> Start Growing Your Wealth Today </h2>
                <h2> SolidBlock Explained </h2>
                <h2> The Buzz on SolidBlock </h2>
                <h2> Looking to tokenize your asset and raise capital? </h2>
                <h2> Why SolidBlock </h2>
                <h2> Strategic Partners </h2>
                <h2> The BlockSolid Podcast</h2>
                <h2> Tokenization 101 Course</h2>
                <h2> SolidBlock Blog</h2>
            </div>
            <Helmet>
                <title>Distributed investment banking - DIBS Capital</title>
                <meta
                    name="description"
                    content="DIBS Capital is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities."
                />
                <meta name="keywords" content="Distributed investment banking" />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://sb-dev.solidblock.net/home/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Distributed investment banking - DIBS Capital" />
                <meta property="og:description" content="DIBS Capital is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities." />
                <meta property="og:image" content={`${baseUrl}${homeOG}`} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="sb-dev.solidblock.net" />
                <meta property="twitter:url" content="https://sb-dev.solidblock.net/home/" />
                <meta name="twitter:title" content="Distributed investment banking - DIBS Capital" />
                <meta name="twitter:description" content="DIBS Capital is powering the world's digital assets: end-to-end tokenization platform for real estate- and equity-backed securities." />
                <meta name="twitter:image" content={`${baseUrl}${homeOG}`} />

                {/* <!-- Google Analytics -->  */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-1YHPQK13C8"></script>
                <script async>
                    {`
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-1YHPQK13C8');
                `}
                </script>

                {/* <!-- Start Google Tag Manager --> */}
                <script>
                    {`
                    (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KLQWH2PL');
                    `}
                </script>
                {/* <!-- End Google Tag Manager --> */}

                {/* piperdrive start */}
                <script>
                    {`window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 10839267,
                    playbookUuid: '58b7e12b-6dff-4da0-bf4b-be9865b46faf',version: 2};
                    (function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h)
                     {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();
                     `}
                </script>
                <script src="https://leadbooster-chat.pipedrive.com/assets/loader.js" async></script>
                {/* piperdrive end */}
            </Helmet>
            <LandingNav />
            <LandingHero />
            {/* <LandingCards fromSite={userData?.fromSite || ''} /> */}
            <Explained />
            <Buzz />
            <RaiseCapital />
            <Awwards email={userData?.email || ''} />
            <WhySolidblock />
            <Podcast />
            <TokenizationCourse />
            <Blog />
            <StrategicPartners />
            <Subscribe />
            <Footer />
        </div>
    );
}

export default TokenizationPage;
