import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
interface IDataBlog {
  id: number;
  title: {
    rendered: string;
  };
  date: string;
  link: string;
  jetpack_featured_media_url: string;
  excerpt: {
    rendered: string;
  };
}

const aosProps = {
  'data-aos-duration': "1000",
  'data-aos-anchor-placement': 'bottom-bottom',
  'data-aos': "fade-up",
}

const getDecodedString = (str: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value
}
const Blog: React.FC = () => {
  const [seriesData, setData] = useState<IDataBlog[]>();

  useEffect(() => {
    axios
      .get(`https://dibscapital.com/blog/wp-json/wp/v2/posts/`)
      .then((res: any) => {
        setData(res.data.slice(0, 3));
      })
      .catch(()=>{
        console.log("blog api error")
      })
  }, []);

  return (
    <section className="blog">
      <div className="container">
        <div className="title" {...aosProps}>DIBS Blog</div>
        <div className="descr" {...aosProps}>Company news, articles and announcements</div>
        <div className="series">
          {seriesData?.map((item) => {
            return (
              <div 
              className="item" 
              key={item.id} 
              {...aosProps}
              data-aos-anchor-placement= 'center-bottom'
              >
                <img
                  src={item.jetpack_featured_media_url}
                  loading="lazy"
                  alt=""
                  className="preview"
                  onClick={() => window.open(`${item.link}`)}
                />
                <div className="content-date">
                  <div className="itemTitle" onClick={() => window.open(`${item.link}`)}>{getDecodedString(item.title.rendered)}</div>
                  <div className="date">{item.date.slice(0, 10)}</div>
                  <div className="date">{getDecodedString(item.excerpt.rendered).replace('<p>', '').replace('</p>', '')}</div>
                  <div
                    className="moreBtn"
                    onClick={() => window.open(`${item.link}`)}
                  >
                    Read more &gt;
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <span
          {...aosProps}
          className="enter"
          onClick={() => window.open("https://blog.dibscapital.com/")}
        >
          See more
        </span>
      </div>
    </section>
  );
}

export default Blog;
