import React from "react";
import podcast from "../../images/landing/podcast.png";
import play from "../../images/landing/play.svg";
import axios from "axios";
import { useEffect, useState } from "react";

interface IDataPodcast {
  id: number;
  title: {
    rendered: string;
  };
  date: string;
  link: string;
  player_link: string;
  episode_featured_image: string;
  meta: {
    duration: string;
  };
}

const aosProps = {
  'data-aos-duration': "1000",
  'data-aos-anchor-placement': 'bottom-bottom',
  'data-aos':"fade-up",
}

const getDecodedString = (str: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value
}

const Podcast: React.FC = () => {
  const [seriesData, setData] = useState<IDataPodcast[]>();

  useEffect(() => {
    axios
      .get(`https://dibscapital.com/podcast/wp-json/ssp/v1/episodes/`)
      .then((res: any) => {
        setData(res.data.slice(0, 4));
      })
      .catch(()=>{
        console.log("podcast api error")
      })
  }, []);

  return (
    <section className="podcast">
      <div className="container">
        <img src={podcast} alt="" {...aosProps} loading="lazy"/>
        <div className="title" {...aosProps}>The BlockSolid Podcast</div>
        <div className="descr" {...aosProps}>
          We talk about the newest technologies that revolutionize the 
          world of assets and finance.
        </div>
        <div className="series">
          {seriesData?.map((item) => {
            return (
              <div className="item" key={item.id} {...aosProps}>
                <img
                  loading="lazy"
                  src={item.episode_featured_image}
                  alt=""
                  className="preview"
                  onClick={() => window.open(`${item.link}`)}
                />
                <div className="right">
                  <div
                    className="itemTitle"
                    onClick={() => window.open(`${item.link}`)}
                  >
                    {getDecodedString(item.title.rendered)}
                  </div>
                  <div className="date">{item.date.slice(0, 10)}</div>
                  <div className="play">
                    <img
                      src={play}
                      alt=""
                      onClick={() => window.open(`${item.link}`)}
                      loading="lazy"
                    />
                    <div className="time">{item.meta.duration}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <span
          {...aosProps}
          className="enter"
          onClick={() => window.open("https://dibscapital.com/podcast/")}
        >
          See More Episodes
        </span>
      </div>
    </section>
  );
}

export default Podcast;
