import React from 'react';
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { useState } from 'react';
import { validateEmail } from '../../../constants/regex';
import mautic from 'mautic-tracking';
import closeIconSvg from '../../../images/close.svg'
import './modal.scss'

type DownloadModalProps={    
    isOpen:boolean
    SetIsModalOpen:(state:boolean)=>void
}

const  DownloadModal:React.FC<DownloadModalProps> = 
    ({isOpen,SetIsModalOpen})=> {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const downloadBook = () => {
        setError("");
        if (!localStorage.getItem("token")) {
            if (!email) {
                setError("Please, enter your email");
                return;
            }
            if (!validateEmail(email)) {
                setError("Invalid email");
                return;
            }
        }
        mautic.pageView({ email: email, tags: 'download_book'});
        const docLink = 'https://sb-all-documents.s3.amazonaws.com/CRPM+Final+Draft.pdf'
        const a = document.createElement("a");
        a.href = docLink;
        a.target="_blank"
        a.download = 'true'
        a.click();
        SetIsModalOpen(false)
    }

    return (
        <Dialog
            className="verify-modal"
            open={isOpen}
            onClose={() => SetIsModalOpen(false)}>
            <span className="title" style={{color:"#0EC22B"}}>
                Download Digital Asset Handbook
            </span>
            <span className="descr" style={{width:"80%"}}>
                Enter your email to download the digital asset handbook.
            </span>
            <div className="input">
                <input
                    className="emailDownload"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                }
                />
                <Button
                    style={{ backgroundColor: "rgb(14, 194, 43)" }}
                    variant="contained"
                    className="downloadBtn"
                    onClick={downloadBook}>
                    Download
                </Button>
            </div>
            <span className="input-error">{error}</span>
            <img src={closeIconSvg} 
                loading="lazy"
                alt="Close" 
                className="accreditation-close-icon download"
                style={{top: "4%", right: "2%"}}
                onClick={() => {
                    SetIsModalOpen(false)
                }} 
            />
        </Dialog>
    )
}

export default DownloadModal;